<template>
  <patient-sidebar-panel-listing
    title="Antécédents"
    scrollable-modal
    create-label="Ajouter un ou plusieurs antécédents"
    edit-label="Modifier un antécédent"
    no-data-text="Aucune donnée"
    :items="sortedPatientAntecedents"
    :item-model-class="PatientAntecedent"
    :loading="isFetchingAntecedents"
    :patient="patient"
    group-by="type"
    :form="PatientAntecedentsForm"
    :value-getter="modalValueGetter"
    @submit-success="getAntecedents"
  >
    <template #category-title="{ category }">
      <span v-test="'antecedent-type'">{{ getAntecedentFormattedType(category) }}</span>
    </template>
    <template #item="{ item, edit }">
      <app-hoverable-actions-wrapper
        tag="article"
        :inset-x="4"
        :actions="getActions(item, edit)"
      >
        <p>
          <app-tag
            v-if="item.icd10Code "
            v-test="'antecedent-code'"
            x-small
            color="content"
            class="px-2 mr-2"
          >
            {{ item.icd10Code }}
          </app-tag>
          <span v-test="'antecedent-label'">
            {{ getAntecedentLabel(item) }}
            <n-truncable-text
              v-if="item.note"
            >
              {{ item.note }}
            </n-truncable-text>
          </span>
        </p>
      </app-hoverable-actions-wrapper>
    </template>
  </patient-sidebar-panel-listing>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';

import DeleteActionMenuItem from '@/components/ui/actionsMenu/classes/DeleteActionMenuItem';
import EditActionMenuItem from '@/components/ui/actionsMenu/classes/EditActionMenuItem';
import { PATIENT_ANTECEDENT_MERCURE_STATUS } from '@/constants/index';
import { ANTECEDENT_TYPES } from '@/modules/patient/constants';
import PatientAntecedent from '@/modules/patient/models/healthEntity/PatientAntecedent';
import PatientFamilyAntecedent from '@/modules/patient/models/healthEntity/PatientFamilyAntecedent';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { deleteFromAPI, getFromAPI } from '@/services/api';
import { pluralize } from '@/utils/functions/words';

import AppHoverableActionsWrapper from '@/components/ui/hoverableActionsWrapper/AppHoverableActionsWrapper.vue';
import AppTag from '@/components/ui/tag/AppTag.vue';
import PatientSidebarPanelListing from '@/modules/patient/components/patientFile/PatientSidebarPanelListing.vue';
import PatientAntecedentsForm from '@/modules/patient/components/patientFile/riskFactors/antecedents/PatientAntecedentsForm.vue';

export default {
  name: 'PatientAntecedentsListing',
  components: {
    AppHoverableActionsWrapper,
    PatientSidebarPanelListing,
    AppTag,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      PatientAntecedent,
      PatientAntecedentsForm,
      patientAntecedents: [],
      isFetchingAntecedents: false,
    };
  },
  computed: {
    ...mapGetters('app', { FAMILY_RELATIONS: 'getFamilyRelations' }),
    ...mapGetters('patient', ['getPatientAntecedentLinks', 'getMercurePatientAntecedent']),
    sortedPatientAntecedents () {
      const patientAntecedentLinks = this.getPatientAntecedentLinks(this.patient['@id']);
      if (patientAntecedentLinks) {
        this.setPatientAntecedentsWithMercure(patientAntecedentLinks);
      }

      const antecedentTypeValues = Object.values(ANTECEDENT_TYPES).map(type => type.value);
      return [...this.patientAntecedents].sort((a, b) => antecedentTypeValues.indexOf(a.type) - antecedentTypeValues.indexOf(b.type));
    },
  },
  created () {
    this.initAntecedents();
  },
  methods: {
    ...mapMutations('patient', ['SET_PATIENT_ANTECEDENT_LINKS', 'RESET_PATIENT_ANTECEDENT_LINKS']),
    async getAntecedents () {
      const { data } = await getFromAPI(`${this.patient.getIri()}/medical_antecedents?exists[habitus]=false`);
      this.patientAntecedents = data['hydra:member'].map((antecedent) => {
        if (antecedent.type === 'family') {
          return new PatientFamilyAntecedent({
            ...antecedent,
            familyRelationship: antecedent.familyRelationship['@id'],
          });
        }
        return new PatientAntecedent(antecedent);

      });
      await this.SET_PATIENT_ANTECEDENT_LINKS({
        patientIri: this.patient['@id'],
        patientAntecedents: this.patientAntecedents,
      });
    },
    async initAntecedents () {
      this.isFetchingAntecedents = true;
      try {
        await this.getAntecedents();
      } finally {
        this.isFetchingAntecedents = false;
      }
    },
    setPatientAntecedentsWithMercure (patientAntecedentLinks) {
      Object.entries(patientAntecedentLinks).forEach(([key, antecedentLink]) => {
        if (antecedentLink === PATIENT_ANTECEDENT_MERCURE_STATUS.INIT) {
          return;
        }
        if (antecedentLink === PATIENT_ANTECEDENT_MERCURE_STATUS.DELETE) {
          this.deletePatientAntecedentWithMercure(key);
        }
        if (antecedentLink === PATIENT_ANTECEDENT_MERCURE_STATUS.CREATE) {
          this.createPatientAntecedentWithMercure(key);
        }
        if (antecedentLink === PATIENT_ANTECEDENT_MERCURE_STATUS.UPDATE) {
          this.updatePatientAntecedentWithMercure(key);
        }
      });
    },
    async deletePatientAntecedentWithMercure (patientAntecedentIri) {
      this.patientAntecedents = this.patientAntecedents.filter(item => item['@id'] !== patientAntecedentIri);
      this.RESET_PATIENT_ANTECEDENT_LINKS({
        patientIri: this.patient['@id'],
        patientAntecedentLinkIri: patientAntecedentIri,
        removeLink: true,
      });
    },
    createPatientAntecedentWithMercure (patientAntecedentLink) {
      const mercurePatientAntecedent = this.getMercurePatientAntecedent(patientAntecedentLink);
      if (! this.patientAntecedents.find(item => item['@id'] === patientAntecedentLink) && mercurePatientAntecedent) {
        this.patientAntecedents.push(mercurePatientAntecedent);
      }
      this.RESET_PATIENT_ANTECEDENT_LINKS({
        patientIri: this.patient['@id'],
        patientAntecedentLinkIri: patientAntecedentLink,
        removeLink: false,
      });
    },
    updatePatientAntecedentWithMercure (patientAntecedentLink) {
      const mercurePatientAntecedent = this.getMercurePatientAntecedent(patientAntecedentLink);
      const index = this.patientAntecedents.findIndex(item => item['@id'] === patientAntecedentLink);
      if ((index !== - 1) && mercurePatientAntecedent) {
        this.patientAntecedents[index] = mercurePatientAntecedent;
      }
      this.RESET_PATIENT_ANTECEDENT_LINKS({
        patientIri: this.patient['@id'],
        patientAntecedentLinkIri: patientAntecedentLink,
        removeLink: false,
      });
    },
    getAntecedentFormattedType (antecedentType) {
      if (antecedentType === ANTECEDENT_TYPES.NOT_CODIFIED.value) {
        return 'Non codifiés CIM10';
      }
      return pluralize(ANTECEDENT_TYPES[antecedentType.toUpperCase()]?.text || ANTECEDENT_TYPES.EMPTY.text);
    },
    getAntecedentLabel (antecedent) {
      if (antecedent.type === ANTECEDENT_TYPES.FAMILY.value) {
        const familyRelation = this.FAMILY_RELATIONS.find(relation => relation['@id'] === antecedent.familyRelationship);
        return `${antecedent.label} (${familyRelation.label.toLowerCase()})`;
      }
      return antecedent.label;
    },
    modalValueGetter (antecedent) {
      if (antecedent) {
        return [antecedent];
      }
      return [new PatientAntecedent({ type: ANTECEDENT_TYPES.MEDICAL.value })];
    },
    getActions (item, editMethod) {
      return [
        new EditActionMenuItem({ callback: () => editMethod(item) }),
        new DeleteActionMenuItem({
          callback: () => {
            NovaTools.dialog.confirm(
              'Supprimer l\'antécédent',
              'Êtes-vous sûr(e) de vouloir supprimer l\'antécédent ?',
              async () => {
                await deleteFromAPI(item['@id']);
                this.removeAntecedentValue(item);
                NovaTools.notify.success('L\'antécédent a été supprimé avec succès');
              },
            );
          },
        })];
    },
    removeAntecedentValue (value) {
      const index = this.patientAntecedents.indexOf(value);
      if (index !== - 1) {
        this.patientAntecedents.splice(index, 1);
      }
    },
  },
};
</script>